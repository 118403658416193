import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Create,
  SimpleForm,
  PasswordInput,
  TextInput,
  SelectArrayInput,
  SelectInput,
} from 'react-admin';
export const UserCreate = (props) => {
    const translate = useTranslate();
    return (
      <Create {...props}>
        <SimpleForm>
          <TextInput source="name" label={translate('user.firstName')} />
          <TextInput source="surname" label={translate('user.lastName')} />
          <TextInput source="email" label={translate('user.email')} />
          <PasswordInput
            source="password"
            label={translate('user.password')}
            helperText={translate('user.password_help')}
          />
          <PasswordInput
            source="confirm_password"
            label={translate('user.confirm_password')}
          />
          <SelectInput
            source="gender"
            label={translate('user.gender')}
            choices={[
              { id: '1', name: translate('user.gender_femenino') },
              { id: '2', name: translate('user.gender_masculino') },
              { id: '3', name: translate('user.gender_nobinario') },
            ]}
          />
          <SelectArrayInput
            source="roles"
            label={translate('user.role')}
            choices={[
              { id: 'ROLE_ADMIN', name: translate('user.role_admin') },
              { id: 'ROLE_USER', name: translate('user.role_user') },
            ]}
          />
        </SimpleForm>
      </Create>
    );
};
