// VENDOR
import * as React from 'react';
import {
  AppBar,
  useSetLocale, useLocale, useTranslate  
} from 'react-admin';
import { Box, Typography, Button, makeStyles } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
  root: {
    
    '& [class^="MuiToolbar"]': {
      paddingRight: '10px',
    },
    '& [class*="menuButton"]': {
      marginLeft: 2,
      marginRight: 2,
    },
  },
}));

const MyAppBar = (props) => {
  const classes = useStyles(props);
  const setLocale = useSetLocale();
  const locale = useLocale();
  const translate = useTranslate(); 

  return (
    <div className={classes.root}>
      <AppBar {...props}>
        <Box flex="1">
          <Typography variant="h6" id="react-admin-title">Juntos    </Typography>
        </Box>
        <div>
          {locale === 'en' ? (
            <Button onClick={() => setLocale('es')}>
              {translate('appBar.spanish')}
            </Button>
          ) : (
            <Button onClick={() => setLocale('en')}>
              {translate('appBar.english')}
            </Button>
          )}
        </div>
      </AppBar>
    </div>
  );
};

export default MyAppBar;
