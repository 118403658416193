import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  SimpleList,
  SearchInput,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';

export const BannerList = props => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const postFilters = [<SearchInput source="q" alwaysOn />];
  

   return (
     <List {...props} title={translate('banner.title')} filters={postFilters}>
       {isSmall ? (
         <SimpleList
           primaryText={(record) => `${record.link}`}
           secondaryText={(record) => record.isActive}
         />
       ) : (
         <Datagrid rowClick="edit">
           <TextField source="id" />
           <TextField source="name" label={translate('banner.name')} />
           <TextField source="position" label={translate('banner.position')} />
           <DateField
             source="since"
             label={translate('banner.since')}
             locales="fr-FR"
             showTime={true}
           />
           <DateField
             source="until"
             locales="fr-FR"
             label={translate('banner.until')}
             showTime={true}
           />
         </Datagrid>
       )}
     </List>
   );

};