import * as React from 'react';
import { RouteWithoutLayout } from 'react-admin';
import ForgotPassword from '../forgotPassword';
import ResetPassword from '../resetPassword';
import ThemedCustomRouteNoLayout from '../layout/themedCustomRouteNoLayout';

export default [
  <RouteWithoutLayout
    exact
    path="/forgot_password"
    component={ThemedCustomRouteNoLayout}
  />,
  <RouteWithoutLayout exact path="/reset_password" component={ResetPassword} />,
];
