import config from '../config';

const userService = {
    forgot: ( username: string ) => {
        const request = new Request(`${config.API_URL}/forgot`, {
            method: 'POST',
            body: JSON.stringify({ username }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then((response) => {
                
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return "Siga las instrucciones del email para recuperar su contraseña";
            })
            .catch(() => {
                
                throw new Error('Network error');
            });
    },
    reset: (password: string, hashToken: string ) => {
        const request = new Request(`${config.API_URL}/reset/${hashToken}`, {
            method: 'POST',
            body: JSON.stringify({ password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .catch(() => {
                throw new Error('Network error');
            });
    },
    
};

export default userService;