import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  DateTimeInput,
  required,
  SelectInput,
} from 'react-admin';
export const BannerCreate = (props) => {
    const translate = useTranslate();
    return (
      <Create {...props}>
        <SimpleForm>
          <TextInput
            source="name"
            label={translate('banner.name')}
            validate={required()}
          />
          <TextInput
            source="link"
            label={translate('banner.link')}
          />
          <ImageInput
            validate={required()}
            source="image"
            label={translate('banner.image')}
            accept="image/*"
            maxSize={5000000} // 5 MiB
            placeholder={translate('image.placeholder')}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <DateTimeInput
            label={translate('banner.since')}
            source="since"
            options={{ format: 'DD/MM/YYYY' }}
            validate={required()}
          />
          <DateTimeInput
            label={translate('banner.until')}
            source="until"
            options={{ format: 'DD/MM/YYYY' }}
            validate={required()}
          />
          <SelectInput
            source="position"
            label={translate('banner.position')}
            choices={[
              { id: '1', name: 'Banner 1' },
              { id: '2', name: 'Banner 2' },
              { id: '3', name: 'Banner 3' },
              { id: '4', name: 'Banner 4' },
              { id: '5', name: 'Banner 5' },
            ]}
          />
        </SimpleForm>
      </Create>
    );
};
