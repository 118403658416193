import {TextField} from '@material-ui/core';
const Input = ({
  //eslint-disable-line react/prop-types
  meta: { touched, error },
  //eslint-disable-line react/prop-types
  input: inputProps,
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

export default Input;
