import englishMessages from 'ra-language-english';

const customEnglishMessages = {
  ...englishMessages,
  sidebar: {
    dashboard: 'Dashboard',
    users: 'Users',
    banners: 'Banners',
  },
  login: {
    link_forgot_password: 'Forgot Password?',
    email_required: 'Email Required',
    password_required: 'Password Required',
    password: 'Password',
    confirm_password: 'Confirm Password',
    confirm_password_required: 'Confirm Password Required',
    btn: {
      send: 'Send',
    },
  },
  forgot: {
    email: 'Email',
  },
  user: {
    firstName: 'Name',
    lastName: 'Lastname',
    email: 'Email',
    isActive: 'Active',
    createdAt: 'Created',
    updatedAt: 'Updated',
    password: 'Password',
    password_help: 'Fill password only if you want to change it',
    confirm_password: 'Confirm Password',
    gender: 'Gender',
    role: 'Rol',
    role_admin: 'Admin',
    role_user: 'User',
    gender_femenino: 'Women',
    gender_masculino: 'Male',
    gender_nobinario: 'No Binary',
  },
  banner: {
    name: 'Name',
    image: 'Image',
    until: 'Until',
    since: 'Since',
    position: 'Position',
    title: 'List of Banners',
    link: 'Link',
  },
  validation: {
    email: 'Enter a valid email',
  },
  appBar: {
    spanish: 'Es',
    english: 'En',
  },
  image: {
    placeholder: 'Select a image',
  },
};
export default customEnglishMessages;