// VENDOR
import React from 'react';
import { MenuItemLink, useTranslate, useAuthProvider } from 'react-admin';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import UserIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import BannerIcon from '@material-ui/icons/Slideshow';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingTop: '15px',
    background: 'white',
  },
  button: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const SideBar = ({ onMenuClick }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const authProvider = useAuthProvider();

  const menuItems = [
    {
      route: '',
      text: translate('sidebar.dashboard'),
      icon: <HomeIcon />,
    },
    {
      route: 'users',
      text: translate('sidebar.users'),
      icon: <UserIcon />,
    },

    {
      route: 'banners',
      text: translate('sidebar.banners'),
      icon: <BannerIcon />,
    },
  ];
  return (
    <div className={classes.root}>
      {menuItems.map(
        (
          { route, text, icon },
          index,
        ) => {
            return (
              <MenuItemLink
                key={index}
                to={`/${route}`}
                exact={!route}
                primaryText={text}
                leftIcon={icon}
                onClick={onMenuClick}
                className={classes.button}
              />
            );
          }
        
      )}
      <MenuItemLink
        onClick={() => authProvider.logout()}
        to="/login"
        primaryText={translate('ra.auth.logout')}
        leftIcon={<PowerSettingsNewIcon />}
        className={classes.button}
      />
    </div>
  );
};

export default SideBar;
