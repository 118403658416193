import React from 'react';
import { Layout as LayoutRa } from 'react-admin';
import MyAppBar from './AppBar';
import SideBar from './SideBar';

const Layout = (props) => (
  <LayoutRa {...props} menu={SideBar} appBar={MyAppBar} />
);

export default Layout;
