import spanishMessages from '@blackbox-vision/ra-language-spanish';

const customSpanishMessages = {
  ...spanishMessages,
  sidebar: {
    dashboard: 'Inicio',
    users: 'Usuarios',
    banners: 'Banners',
  },
  login: {
    link_forgot_password: 'Olvide la contraseña',
    email_required: 'Ingrese Email',
    password_required: 'Ingrese contraseña',
    password: 'Contraseña',
    confirm_password: 'Confirme contraseña',
    confirm_password_required: 'Confirme contraseña Requerido',
    btn: {
      send: 'Enviar',
    },
  },
  forgot: {
    email: 'Email',
  },
  user: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Email',
    isActive: 'Activo',
    createdAt: 'Creado',
    updatedAt: 'Modificado',
    password: 'Contraseña',
    password_help: 'Ingrese contraseña solo si desea cambiarla',
    confirm_password: 'Confirmar Contraseña',
    gender: 'Genero',
    role: 'Rol',
    role_admin: 'Administrador',
    role_user: 'Usuario',
    gender_femenino: 'Femenino',
    gender_masculino: 'Masculino',
    gender_nobinario: 'No Binario',
  },
  banner: {
    name: 'Nombre',
    image: 'Imagen',
    until: 'Hasta',
    since: 'Desde',
    position: 'Posición',
    title: 'Listado de Banners',
    link: 'URL',
  },
  validation: {
    email: 'Ingrese un email válido',
  },
  appBar: {
    spanish: 'Es',
    english: 'En',
  },
  image: {
    placeholder: 'Seleccione una imagen',
  },
};
export default customSpanishMessages;
