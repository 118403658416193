import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  SelectArrayInput,
  SelectInput,
  PasswordInput,
} from 'react-admin';
export const UserEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" label={translate('user.firstName')} />
        <TextInput source="surname" label={translate('user.lastName')} />
        <BooleanInput source="active" label={translate('user.isActive')} />
        <TextInput source="email" label={translate('user.email')} />
        <PasswordInput source="password" label={translate('user.password')} />
        <PasswordInput
          source="confirm_password"
          label={translate('user.confirm_password')}
        />
        <SelectInput
          source="gender"
          label={translate('user.gender')}
          choices={[
            { id: '1', name: translate('user.gender_femenino') },
            { id: '2', name: translate('user.gender_masculino') },
            { id: '3', name: translate('user.gender_nobinario') },
          ]}
        />
        <SelectArrayInput
          source="roles"
          label={translate('user.role')}
          choices={[
            { id: 'ROLE_ADMIN', name: translate('user.role_admin') },
            { id: 'ROLE_USER', name: translate('user.role_user') },
          ]}
        />
      </SimpleForm>
    </Edit>
  );};
