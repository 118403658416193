import React from 'react';
import {
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { useTranslate, useNotify, useSafeSetState } from 'ra-core';
import userService from '../services/user';
import { useForm, Controller } from 'react-hook-form';


const ResetPassword = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const { handleSubmit, control } = useForm();

  const onSubmit = async (values) => {
    const { password, confirmPassword } = values;
    
      if (password === confirmPassword) {
        try {
          setLoading(true);
          await userService
            .reset(
              password.trim()
            );
        } catch (error) {
         notify(error);
        } finally {
          setLoading(false);
        }
      }
    
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="password"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={translate('login.password')}
            variant="filled"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            type="password"
          />
        )}
        rules={{ required: translate('login.password_required') }}
      />
      <Controller
        name="confirm_password"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={translate('login.confirm_password')}
            variant="filled"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            type="password"
          />
        )}
        rules={{ required: translate('login.confirm_password_required') }}
      />
      <Button
        variant="contained"
        type="submit"
        color="primary"
        disabled={loading}
      >
        {loading && <CircularProgress size={18} thickness={2} />}
        {translate('login.btn.send')}
      </Button>
    </form>
  );
};

export default ResetPassword;
