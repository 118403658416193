import simpleRestProvider from 'ra-data-simple-rest';
import config from '../config';
import { fetchUtils } from 'react-admin';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = JSON.parse(localStorage.getItem('auth'));
  console.log('httpClient', token);
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(config.API_URL, httpClient);

const myDataProvider = {
  ...dataProvider,
  create: (resource, params) => {
     
    if (resource === 'banners') {
        
      return Promise.all([
        convertFileToBase64(params.data.image),
      ]).then(([imageBase64]) => {
        params.data.image = imageBase64;
        return dataProvider.create(resource, params);
      });

    }
    return dataProvider.create(resource, params);
    

  },
  update: async (resource, params) => {
    if (resource === 'banners') {
        /*return Promise.all([convertFileToBase64(params.data.image)]).then(
          ([imageBase64]) => {
            params.data.image = imageBase64 || null;
            return dataProvider.update(resource, params);
          },
        );*/
        const imageBase64 = await convertFileToBase64(params.data.image);
        params.data.image = imageBase64 || null;
        return dataProvider.update(resource, params);
    }
    return dataProvider.update(resource, params);

    
  },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    if (!file.rawFile){
        resolve(null);
    }  
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

export default myDataProvider;
