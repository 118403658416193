import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  SimpleList,
  TextInput,
  SearchInput,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';

export const UsersList = props => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const postFilters = [
      <SearchInput source="q" alwaysOn />,
    ];
   return (
     <List {...props} title="Listado de Usuarios" filters={postFilters}>
       {isSmall ? (
         <SimpleList
           primaryText={(record) => `${record.firstName} ${record.lastName}`}
           secondaryText={(record) => record.email}
         />
       ) : (
         <Datagrid rowClick="edit">
           <TextField source="id" />
           <TextField source="name" label={translate('user.firstName')} />
           <TextField source="surname" label={translate('user.lastName')} />
           <EmailField source="email" label={translate('user.email')} />
           <BooleanField source="active" label={translate('user.isActive')} />
         </Datagrid>
       )}
     </List>
   );

};