import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  DateTimeInput,
  TextInput,
  ImageInput,
  ImageField,
  required,
  SelectInput,
} from 'react-admin';
import config from '../../config';

export const BannerEdit = (props) => {
  const translate = useTranslate();
  const formatImage = (value) => {
    if (!value || typeof value === 'string') {
      // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
      return { src: `${config.PUBLIC_URL_IMAGE}/${value}` };
    } else {
      // Else a new image is selected which results in a value object already having a preview link under the url key
      return value;
    }
  }

  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
  const dateParseRegex = /(\d{4})-(\d{2})-(\d{2})/;

  const convertDateToString = (value) => {
    // value is a `Date` object
    if (!(value instanceof Date) || isNaN(value.getDate())) return '';
    const pad = '00';
    const yyyy = value.getFullYear().toString();
    const MM = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
  };

  const dateFormatter = (value) => {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value == null || value === '') return '';
    if (value instanceof Date) return convertDateToString(value);
    // Valid dates should not be converted
    if (dateFormatRegex.test(value)) return value;

    return convertDateToString(new Date(value));
  };

  const dateParser = (value) => {
    //value is a string of "YYYY-MM-DD" format
    const match = dateParseRegex.exec(value);
    if (match === null) return;
    const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
    if (isNaN(d.getDate())) return;
    return d;
  };

  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput
          source="name"
          label={translate('banner.name')}
          validate={required()}
        />
        <TextInput source="link" label={translate('banner.link')} />
        <ImageInput
          validate={required()}
          format={formatImage}
          source="image"
          label={translate('banner.image')}
          accept="image/*"
          maxSize={5000000} // 5 MiB
          placeholder={translate('image.placeholder')}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <DateTimeInput
          label={translate('banner.since')}
          source="since"
          options={{ format: 'DD/MM/YYYY' }}
          validate={required()}
        />
        <DateTimeInput
          label={translate('banner.until')}
          source="until"
          options={{ format: 'DD/MM/YYYY' }}
          validate={required()}
        />
        <SelectInput
          source="position"
          label={translate('banner.position')}
          choices={[
            { id: '1', name: 'Banner 1' },
            { id: '2', name: 'Banner 2' },
            { id: '3', name: 'Banner 3' },
            { id: '4', name: 'Banner 4' },
            { id: '5', name: 'Banner 5' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );};
