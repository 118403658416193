import * as React from "react";
import {
  Admin,
  Resource,
  resolveBrowserLocale,
} from 'react-admin';
import UserContainer from './Users';
import BannerContainer from './components/Banner';
import myDataProvider from './dataProvider';
import Dashboard from './dashboard';
import authProvider from './authProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import customEnglishMessages from './i18n/en';
import customSpanishMessages from './i18n/es';
import Layout from './layout';
import customRoutes from "./routes/customRoutes";
import LoginPage from './loginPage';

const messages = {
  es: { ...customSpanishMessages },
  en: { ...customEnglishMessages },
};



const i18nProvider = polyglotI18nProvider(
  (locale) => (messages[locale] ? messages[locale] : messages.es),
  resolveBrowserLocale(),
);


const App = () => (
  <Admin
    title="Juntos"
    i18nProvider={i18nProvider}
    locale="es"
    dashboard={Dashboard}
    layout={Layout}
    dataProvider={myDataProvider}
    authProvider={authProvider}
    requireAuth
    customRoutes={customRoutes}
    loginPage={LoginPage}
    disableTelemetry
  >
    <Resource name="users" {...UserContainer} />
    <Resource name="banners" {...BannerContainer} />
  </Admin>
);

export default App;