import { ThemeProvider } from '@material-ui/core/styles';

import { theme } from './theme';
import ForgotPassword from '../forgotPassword';


const ThemedCustomRouteNoLayout = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <ForgotPassword {...props} />
    </ThemeProvider>
  );
};

export default ThemedCustomRouteNoLayout;
